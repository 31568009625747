import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import React from 'react'
import checkIcon from '../data/images/developement-durable/check-icon.png'
import { breakpoints } from '../utils/styles'

const DeveloppementDurable = () => {
  const images = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headerBackground: file(
        relativePath: { eq: "images/developement-durable/header_coffee.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paysage: file(
        relativePath: { eq: "images/developement-durable/paysage.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // console.log(images)

  return (
    <div>
      <SEO title="Developpement durable" description="Page Developpement durable" />
      <HeaderImg
        fluid={images.headerBackground.childImageSharp.fluid}
        alt='jh&co'
      />
      <Container>
        <Logo fixed={images.logo.childImageSharp.fixed} alt='jh&co' />
        <BackgroundImg
          fluid={images.paysage.childImageSharp.fluid}
          alt='jh&co'
        />
        <WrapperContent>
          <ContentTitle>
            Developpement
            <br /> Durable
          </ContentTitle>
          <ContentTextBox>
            <CheckIcon src={checkIcon} />
            <ContentText>
              Nous offrons à nos clients, la possibilité de récupérer le marc de
              notre café. Le marc est bien connu comme étant un excellent allié
              beauté et ses vertus sont nombreuses (Il est Il est exfoliant,
              brûle graisse, désodorisant,...)
            </ContentText>
          </ContentTextBox>

          <ContentTextBox>
            <CheckIcon src={checkIcon} />
            <ContentText>
              Nous compostons nos déchets organiques pour pouvoir à terme créer
              notre propre engrais naturel et nous veillons à ce que nos
              différents emballages (sacs, pochettes, gobelets...) soient en
              carton ou papier recyclables.
            </ContentText>
          </ContentTextBox>

          <ContentTextBox>
            <CheckIcon src={checkIcon} />
            <ContentText>
              Nos cuillères à café sont en bois et nos serviettes en papier,
              quant à nos caissettes (destinées à la cuisson des muffins),
              celles-ci sont en papier certifié FSC, non blanchi au chlore.
            </ContentText>
          </ContentTextBox>

          <ContentTextBox>
            <CheckIcon src={checkIcon} />
            <ContentText>
              Enfin, nous valorisons les circuits courts pour une meilleure
              traçabilité mais aussi afin de garantir un approvisionnement
              local, une parfaite maîtrise de nos fournisseurs et de la qualité
              de nos produits.
            </ContentText>
          </ContentTextBox>
          <Button href='https://jhnco-clickncollect.fr'>
            Click’n’Collect
          </Button>
        </WrapperContent>
      </Container>
    </div>
  )
}

export default DeveloppementDurable

export const HeaderImg = styled(Img)`
  margin: 0 auto;
  width: 60%;
  height: 100%;

  @media (max-width: ${breakpoints.s}px) {
    width: 95%;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -1em;
  margin-bottom: 3em;

  @media (max-width: ${breakpoints.s}px) {
    width: 95%;
    margin: 0 auto 3em auto;
  }
`

export const Logo = styled(Img)`
  margin-bottom: -2em;
  z-index: 20;
`

export const BackgroundImg = styled(Img)`
  max-width: 55em;
  width: 70%;
  margin-top: -3em;
  border-radius: 20px;

  @media (max-width: ${breakpoints.l}px) {
    width: 90%;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-bottom: 1em;
  }
`

export const WrapperContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #12282e;
  padding: 2em 2em 4em 2em;
  max-width: 35em;
  width: 45%;
  height: 40em;
  margin-top: -11em;
  z-index: 20;
  border-radius: 20px;

  @media (max-width: ${breakpoints.l}px) {
    width: 70%;
    margin-top: -7em;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: auto;
    margin-top: 0;
    padding: 2em 1em 4em 1em;
    height: 45em;
  }
`

export const ContentTitle = styled.p`
  color: #eed7b8;
  font-size: 2.2em;
  text-align: center;
  margin: 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.5em;
  }
`
export const ContentTextBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;

  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
  }
`

export const CheckIcon = styled.img`
  width: 2.8em;
`

export const ContentText = styled.p`
  display: block;
  width: 85%;
  font-size: 0.9em;
  color: white;
  font-family: 'Avenir Light', serif;
  font-weight: 300;

  @media (max-width: ${breakpoints.s}px) {
    width: 80%;
  }
`

export const Button = styled.a`
  position: absolute;
  bottom: -1em;
  background-color: #eed7b8;
  border-radius: 30px;
  width: 12em;
  height: 2em;
  color: #12282e;
  text-align: center;
  line-height: 2;
  font-size: 1.4em;
  text-decoration: none;

  :hover {
    background-color: #2b5c6b;
    color: #eed7b8;
    cursor: pointer;
  }
`